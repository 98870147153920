import(/* webpackMode: "eager", webpackExports: ["Analytics"] */ "/home/runner/work/kc-fonden-t3-mui/kc-fonden-t3-mui/node_modules/.pnpm/@vercel+analytics@1.3.1_next@15.0.0-canary.65_react@19.0.0-rc-827cbea417-20240606/node_modules/@vercel/analytics/dist/react/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["SpeedInsights"] */ "/home/runner/work/kc-fonden-t3-mui/kc-fonden-t3-mui/node_modules/.pnpm/@vercel+speed-insights@1.0.12_next@15.0.0-canary.65_react@19.0.0-rc-827cbea417-20240606/node_modules/@vercel/speed-insights/dist/next/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["SessionProvider"] */ "/home/runner/work/kc-fonden-t3-mui/kc-fonden-t3-mui/node_modules/.pnpm/next-auth@5.0.0-beta.18_next@15.0.0-canary.65_react@19.0.0-rc-827cbea417-20240606/node_modules/next-auth/react.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/kc-fonden-t3-mui/kc-fonden-t3-mui/node_modules/.pnpm/next@15.0.0-canary.65_@babel+core@7.25.8_@opentelemetry+api@1.9.0_babel-plugin-react-compiler_bwxlkb6d5owwim2sov67dt5c6q/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-sans\"}],\"variableName\":\"fontSans\"}");
;
import(/* webpackMode: "eager" */ "/home/runner/work/kc-fonden-t3-mui/kc-fonden-t3-mui/src/app/index.module.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/kc-fonden-t3-mui/kc-fonden-t3-mui/src/components/analytics/CookiePolicyProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["useTranslation","default"] */ "/home/runner/work/kc-fonden-t3-mui/kc-fonden-t3-mui/src/components/translate/TranslationProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ShadcnThemeProvider"] */ "/home/runner/work/kc-fonden-t3-mui/kc-fonden-t3-mui/src/shadcn/theme-provider/provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/home/runner/work/kc-fonden-t3-mui/kc-fonden-t3-mui/src/shadcn/ui/toaster.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/kc-fonden-t3-mui/kc-fonden-t3-mui/src/styles/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["TRPCReactProvider"] */ "/home/runner/work/kc-fonden-t3-mui/kc-fonden-t3-mui/src/trpc/react.tsx");
